























import { Component, Vue } from "vue-property-decorator";
import { DefaTransitionFadeOutIn } from "@defa-as/components";
import { ROUTE_NAMES } from "@/router/route-names";
import RegistrationForm from "@/components/registration/RegistrationForm.vue";

@Component({
  components: {
    RegistrationForm,
    DefaTransitionFadeOutIn,
  },
})
export default class ViewRegistration extends Vue {
  loading = false;
  registrationSuccessful = false;

  get loginLink() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  onRegister() {
    this.registrationSuccessful = true;
  }
}
