

















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaConfirmation } from "@defa-as/components";
import { findRouteByName } from "@defa-as/utils";
import { routes } from "@/router";
import { ROUTE_NAMES } from "@/router/route-names";
import { httpClient } from "@/http";
import {
  isRetailerGroupForDomainPublic,
  originForRetailerGroupDefa,
} from "@/config/portals/retailer-groups";

@Component({
  components: {
    DefaConfirmation,
  },
})
export default class ViewRegistrationConfirm extends Vue {
  @Prop({ required: true }) readonly hash!: string;

  get isRetailerGroupForDomainPublic() {
    return isRetailerGroupForDomainPublic();
  }

  get httpClient() {
    return httpClient;
  }

  get confirmationUrl() {
    return `/users/confirm/${this.hash}`;
  }

  get approvalUrl() {
    return `${originForRetailerGroupDefa()}${
      findRouteByName(
        routes,
        ROUTE_NAMES.CONNECT_RETAILER_FOR_PUBLIC_RG
      ).path.split("(")[0]
    }`;
  }

  get denialUrl() {
    return `${originForRetailerGroupDefa()}${
      findRouteByName(routes, ROUTE_NAMES.DENY_USER_FOR_PUBLIC_RG).path.split(
        "("
      )[0]
    }`;
  }

  get loginRoute() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }
}
