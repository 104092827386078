







































































































import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import type { Nullable } from "@defa-as/utils";
import {
  extractDomainFromEmailIncludingAtSymbol,
  serviceDetails,
} from "@defa-as/utils";
import {
  DefaBackendErrorMessages,
  DefaBaseButton,
  DefaBaseInput,
  DefaBaseTooltip,
} from "@defa-as/components";
import InputFullName from "@/components/forms/InputFullName.vue";
import InputTelephone from "@/components/forms/InputTelephone.vue";
import PasswordWithConfirmation from "@/components/forms/PasswordWithConfirmation.vue";
import { isRetailerGroupForDomainPublic } from "@/config/portals/retailer-groups";
import { register } from "@/http/requests/requests-user";
import type { AxiosError } from "axios";

@Component({
  components: {
    DefaBaseInput,
    DefaBaseTooltip,
    DefaBaseButton,
    DefaBackendErrorMessages,
    InputFullName,
    InputTelephone,
    ValidationObserver,
    PasswordWithConfirmation,
  },
})
export default class RegistrationForm extends Vue {
  loading = false;

  model = {
    email: "",
    telephoneNumber: "",
    fullName: "",
    password: "",
    passwordConfirm: "",
    retailerNameInput: "",
    retailerOrgNumberInput: "",
  };
  error = {
    emailUsed: "",
    axiosError: null as Nullable<AxiosError>,
  };

  get isAuthorizationError() {
    return this.error.axiosError?.response?.status === 403;
  }

  get isUserModelError() {
    return this.error.axiosError?.response?.status === 400;
  }

  get emailDomain() {
    return extractDomainFromEmailIncludingAtSymbol(this.error.emailUsed);
  }

  get isRetailerGroupForDomainPublic() {
    return isRetailerGroupForDomainPublic();
  }

  get serviceDetails() {
    return serviceDetails();
  }

  onInputPassword(value: string) {
    this.model.password = value;
  }

  onInputPasswordConfirm(value: string) {
    this.model.passwordConfirm = value;
  }

  async createUser() {
    this.loading = true;
    try {
      await register({
        email: this.model.email,
        password: this.model.password,
        fullName: this.model.fullName,
        telephoneNumber: this.model.telephoneNumber,
        retailerNameInput: this.model.retailerNameInput,
        retailerOrgNumberInput: this.model.retailerOrgNumberInput,
      });
      this.$emit("register");
    } catch (error) {
      this.error.axiosError = error;
      this.error.emailUsed = this.model.email;
    } finally {
      this.loading = false;
    }
  }
}
