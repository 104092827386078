
















































import { Component, Vue } from "vue-property-decorator";
import { DefaBaseButton, DefaLoaderCircle } from "@defa-as/components";
import type { Retailer } from "@defa-as/utils";
import { serviceDetails } from "@defa-as/utils";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { linkRetailer } from "@/http/requests/requests-user";
import BaseSelect from "@/components/forms/inputs-generic/BaseSelect.vue";
import { userModule } from "@/store/modules/user";
import { resetModule } from "@/store/modules/reset";
import { ROUTE_NAMES } from "@/router/route-names";
import RetailerForm from "@/components/retailer/RetailerForm.vue";
import { getRetailers } from "@/http/requests/requests-retailer";

@Component({
  components: {
    RetailerForm,
    DefaLoaderCircle,
    BaseSelect,
    DefaBaseButton,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ViewRetailerConnection extends Vue {
  retailerOptions: Retailer[] = [];
  loading = {
    linking: false,
    retailerOptions: false,
  };

  get resetModule() {
    return resetModule;
  }

  get serviceDetails() {
    return serviceDetails();
  }

  get userModule() {
    return userModule;
  }

  async created() {
    this.loading.retailerOptions = true;
    try {
      this.retailerOptions = await getRetailers();
    } finally {
      this.loading.retailerOptions = false;
    }
  }

  async linkRetailer(selectedRetailer: Retailer["id"]) {
    this.loading.linking = true;
    try {
      const user = await linkRetailer(selectedRetailer);
      await this.userModule.setUser({ user });
      const redirectTo = this.$route.query["redirect-to"];
      if (userModule.canUsePortal) {
        return await this.$router.replace(
          typeof redirectTo === "string"
            ? redirectTo
            : {
                name: ROUTE_NAMES.ROOT,
              }
        );
      }
      await this.$router.replace({
        name: ROUTE_NAMES.GDPR_DOCUMENT_CONFIRMATION,
        query: {
          ...(redirectTo
            ? {
                "redirect-to": redirectTo,
              }
            : null),
        },
      });
    } finally {
      this.loading.linking = false;
    }
  }
}
